import React from 'react'
import NewScV from './components/NewScV'

import './App.css'
import './utils/reset.css'

function App() {
    return (
        <div className='App'>
            <NewScV/>
        </div>
    )
}

export default App

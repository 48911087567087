import React, {useState} from 'react';
import './MainBlock.css'
import {ReactComponent as BG3} from "../assets/test/123.svg";
import {CardData, getUniqueCategories, menuItem} from '../utils/helper'
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import {ReactComponent as Logo1} from "../assets/img/logo/CRA.svg";
import {ReactComponent as Logo2} from "../assets/img/logo/gerb.svg";
import {ReactComponent as Logo3} from "../assets/img/logo/apple.svg";
import LogoCRA from "../assets/img/logo/LogoCRA.png";
import LogoM from "../assets/img/logo/maslihat.png";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {lanGs} from "../utils/langData";
import { Pagination, Scrollbar, A11y} from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const MainBlock = ({setClickMenu}) => {
    const [card, setCard] = useState(1)
    const [lang, setLang] = useState('nameRu')

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3
    };
    const settings2 = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3
    };
    return (
        <div className={`MainBlock_wrapper`}>
            <div className={`MainBlock_wrap`}>
                <div className={'MainBlock_double_items'}>
                    <div className={`HeaderBlock_wrap`}>
                        <span   className={`HeaderBlock_item home_icon`} title={"Ситуационный центр города Алматы"}>
                            <img src={LogoCRA} alt="лого ситуационного центра города алматы"/>
                            <div className={"home_icon_text"}>
                                {lang === 'nameRu'? <span>{lanGs['СЦ'][lang].slice(0, 12)}</span>: <span>{lanGs['СЦ'][lang].slice(0, 8)}</span>}
                                {lang === 'nameRu'? <span>{lanGs['СЦ'][lang].slice(12)}</span>: <span>{lanGs['СЦ'][lang].slice(8)}</span>}
                            </div>
                        </span>
                        <a href={"https://almatydc.kz/"} target={"_blank"} className={`HeaderBlock_item `} title={lanGs["Маслихат города Алматы"][lang]}>
                            <img src={LogoM} alt="лого маслихата города алматы"/>
                            <span
                                className={`cifra_logo_text`}>{lanGs["Маслихат города Алматы"][lang].slice(0, 16)}<br/>{lanGs["Маслихат города Алматы"][lang].slice(16)}</span>
                        </a>

                        <a href={"https://almatydc.kz/"} target={"_blank"} className={`HeaderBlock_item `} title={lanGs["Центр развития города Алматы"][lang]}>
                            <Logo1/>
                            <span
                                className={`cifra_logo_text`}>{lanGs["Центр развития города Алматы"][lang].slice(0, 14)}<br/>{lanGs["Центр развития города Алматы"][lang].slice(14)}</span>
                        </a>
                        <a href={"https://www.gov.kz/memleket/entities/almaty?lang=ru"} target={"_blank"} className={`HeaderBlock_item `}
                           title={lanGs["Акимат города Алматы"][lang]}>
                            <Logo2/>
                            {lang === 'nameRu' ?<span className={"Header_logo_text"}>{lanGs["Акимат города Алматы"][lang].slice(0, 14)}
                                <br/>{lanGs["Акимат города Алматы"][lang].slice(14)}</span>:  <span className={"Header_logo_text"}>{lanGs["Акимат города" +
                            " Алматы"][lang].slice(0, 16)}
                            <br/>{lanGs["Акимат города Алматы"][lang].slice(16)}</span>}
                        </a>
                        <a href={"https://digital-almaty.kz/ru"} target={"_blank"} className={`HeaderBlock_item`}
                           title={lanGs["Управление Цифровизации города Алматы"][lang]}>
                            <Logo3/>
                            <div className={`cifra_logo_text`}>
                                <span>{lanGs["Управление Цифровизации города Алматы"][lang].slice(0, 13)}</span>
                                <span>{lanGs["Управление Цифровизации города Алматы"][lang].slice(13, 25)}</span>
                                <span>{lanGs["Управление Цифровизации города Алматы"][lang].slice(25)}</span>
                            </div>
                        </a>
                        <div className={"Contact_block"}>
                            <a href="https://forms.gle/uAdQWfjJuySpGQjh9" target={"_blank"} className={"Contact_block_item Feedback_btn"}>

                                <span>{lanGs["Обратная связь"][lang]}</span>
                            </a>
                        </div>
                        <div className={"lang_block"}>
                            <span onClick={() => setLang("nameRu")} className={`lang_btn_item ${lang === "nameRu" ? "active" : ""}`}>РУС</span>
                            <span>/</span>
                            <span onClick={() => setLang("nameKz")} className={`lang_btn_item ${lang === "nameKz" ? "active" : ""}`}>КАЗ</span>
                        </div>
                    </div>
                    <div className={'MainBlock_text_wrapper'}>

                        <div className="MainBlock_text_wrap">
                            <div className={'MainBlock_text_left_wrap'}>
                                <div className="MainBlock_text_left">
                                    <span>{lanGs['Ситуационный Центр города Алматы'][lang]}</span>
                                    <p>{lanGs['СЦ текст'][lang]}</p>
                                </div>
                            </div>
                            <div className="MainBlock_text_right">
                                <BG3/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="MainBlock_menu_wrap">

                    <Slider {...settings}>
                        {CardData && getUniqueCategories(CardData).map(i => {
                            return <div className={`MainBlock_menu ${card === i.id ? 'activeM' : ''}`} onClick={() => setCard(i.id)}>{i[lang]}</div>
                        })}
                    </Slider>
                </div>
                <div className="MainBlock_card_wrap">
                    <Swiper
                        modules={[Pagination, Scrollbar, A11y]}
                        className="mySwiper"
                        createElements={true}
                        freeMode={true}
                        spaceBetween={0}
                        slidesPerView={3}
                        autoHeight={true}
                        pagination={{ clickable: true }}
                        breakpoints={{
                            // when window width is >= 640px
                            100: {
                                // width: 640,
                                slidesPerView: 1,
                            },
                            // when window width is >= 768px
                            850: {
                                // width: 768,
                                slidesPerView: 2,
                            },
                            1100: {
                                // width: 768,
                                slidesPerView: 3,
                            },
                            1500: {
                                // width: 768,
                                slidesPerView: 4,
                            },
                        }}
                    >
                        {CardData?.filter(fl => fl.id === card).map(i => {
                            return <SwiperSlide>
                                <div className={'MainBlock_card'}>
                                    <img src={i.img} alt=""/>
                                    {i.type === 'service' ? <a className={'MainBlock_card_text'} href={i.link} target={'_blank'}>
                                        {i.name[lang]}
                                    </a> : <span onClick={() => setClickMenu([i.name[lang], i.link])} className={'MainBlock_card_text'}>{i.name[lang]}</span>}
                                </div>
                            </SwiperSlide>
                        })}
                    </Swiper>
                </div>
            </div>
        </div>
    );
};

export default MainBlock;

import React, {useEffect, useState, useCallback} from 'react';
import './BodyBlock.css'
import {titleHeader} from "../../utils/datas";
import axios from 'axios'
import MainBlock from "../MainBlock";

const BodyBlock = ({clickMenu, linkType, dataNav, setClickMenu}) => {

    const [iframeItem, setIframeItem] = useState(); // хранит типы ссылки для модуля
    const [isError, setIsError] = useState();

    let DEV = false



    useEffect(() => {
        setIframeItem(linkType)

    }, [linkType]);
    useEffect(() => {

        if (dataNav && clickMenu && iframeItem && iframeItem[1] !== 'out') {
            axios
                .get(
                    `${clickMenu}`,
                )
                .then((response) => {
                    setIsError(false)

                }).catch(err => {

                setIsError(true)
            })
        }

    }, [clickMenu, dataNav, iframeItem]);
    let LinkIs = () => {
        let frame
        if(iframeItem && iframeItem[1]!== 'dev') {
            frame = iframeItem && iframeItem[1] === 'out' ? 'out' : DEV ? isError ? 'error' : 'local' : 'module'
        }
        else{
            frame= 'dev'
        }

        if (clickMenu) {
            switch (frame) {
                case 'out':
                    return <iframe src={clickMenu[1]} width="100%" height="100%" align="left"/>
                case 'local':
                    return <iframe src={clickMenu[1]} width="100%" height="100%" align="left"/>
                case 'module':
                    return <iframe src={clickMenu && clickMenu[1]} width="100%" height="100%" align="left"/>
                case 'error':
                    return <div className={`errorT`}/>
                case 'dev':
                    return <MainBlock setClickMenu={setClickMenu}/>
                default:
                    break
            }
        }


    }


    return (
        <div className={`BodyBlock_wrap ${clickMenu?.length > 0 && clickMenu[1] === 'dev'? 'itDev': ''}`}>
            <div className={`titleBlockIframe noCopy`}>
                {dataNav && clickMenu && titleHeader(dataNav, clickMenu.length && clickMenu[0]).map(i => <span key={i + Math.random()}>{i}
                    <svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.28453 5.9596L0.92446 0.311665C0.71543 0.108864 0.382711 0.116973 0.181431
                                0.329774C-0.0198495 0.542559 -0.0135623 0.879715 0.195468 1.08252L6.17206
                                6.39031L0.399287 11.9844C0.198007 12.1972 0.204294 12.5344 0.413324
                                12.7372C0.622355 12.94 0.955073 12.9319 1.15634 12.7191L7.30009 6.7664C7.40737
                                6.65296 7.45151 6.50496 7.4415 6.3599C7.445586.21395 7.39598 6.06825 7.28453 5.9596Z" fill="white"/>
                            </svg>
                        </span>)}
            </div>
            <div className={'Iframe_wrapper'}>
                {LinkIs()}
            </div>

        </div>
    );
};

export default BodyBlock;
/**/



import React from "react";
import "./HeaderBlock.css";
import { ReactComponent as Logo1 } from "../../assets/img/logo/CRA.svg";
import { ReactComponent as Logo2 } from "../../assets/img/logo/gerb.svg";
import { ReactComponent as Logo3 } from "../../assets/img/logo/apple.svg";
import { ReactComponent as Form } from "../../assets/img/logo/form.svg";
import LogoCRA from "../../assets/img/logo/LogoCRA.png";
import { lanGs } from "../../utils/langData";

const HeaderBlock = ({
  collapseHeader,
  setCollapseHeader,
  lang = "nameRu",
  setLang,
}) => {
  return (
    <div className={`HeaderBlock_wrapper ${collapseHeader ? "active" : ""}`}>
      <div className={`HeaderBlock_wrap`}>
        <a
          href={"https://opendata.smartalmaty.kz/"}
          target={"_blank"}
          className={`HeaderBlock_item home_icon`}
          title={"Ситуационный центр города Алматы"}
        >
          <img src={LogoCRA} alt="лого ситуационного центра города алматы" />
          <div className={"home_icon_text"}>
            <span>Ситуационный центр</span>
            <span>Главная страница</span>
          </div>
        </a>

        <a
          href={"https://almatydc.kz/"}
          target={"_blank"}
          className={`HeaderBlock_item `}
          title={lanGs["Центр развития города Алматы"][lang]}
        >
          <Logo1 />
          <span className={`cifra_logo_text`}>
            {lanGs["Центр развития города Алматы"][lang].slice(0, 14)}
            <br />
            {lanGs["Центр развития города Алматы"][lang].slice(14)}
          </span>
        </a>
        <a
          href={"https://www.gov.kz/memleket/entities/almaty?lang=ru"}
          target={"_blank"}
          className={`HeaderBlock_item `}
          title={lanGs["Акимат города Алматы"][lang]}
        >
          <Logo2 />
          <span className={"Header_logo_text"}>
            {lanGs["Акимат города Алматы"][lang].slice(0, 6)} <br />{" "}
            {lanGs["Акимат города Алматы"][lang].slice(6)}
          </span>
        </a>
        <a
          href={"https://digital-almaty.kz/ru"}
          target={"_blank"}
          className={`HeaderBlock_item`}
          title={lanGs["Управление Цифровизации города Алматы"][lang]}
        >
          <Logo3 />
          <div className={`cifra_logo_text`}>
            <span>
              {lanGs["Управление Цифровизации города Алматы"][lang].slice(
                0,
                13
              )}
            </span>
            <span>
              {lanGs["Управление Цифровизации города Алматы"][lang].slice(
                13,
                25
              )}
            </span>
            <span>
              {lanGs["Управление Цифровизации города Алматы"][lang].slice(25)}
            </span>
          </div>
        </a>


        <div className={"Contact_block"}>
          <a
            href="https://forms.gle/uAdQWfjJuySpGQjh9"
            target={"_blank"}
            className={"Contact_block_item"}
          >
            <Form />
            <span>{lanGs["Оставить отзыв"][lang]}</span>
          </a>
        </div>
        <a
          href="https://demo.opendata.smartalmaty.kz"
          target="_self"
          className="HeaderBlock_item akim_panel "
        >
          <svg
            fill="none"
            height="24"
            viewBox="0 0 24 24"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="m12 11c2.2091 0 4-1.79086 4-4s-1.7909-4-4-4c-2.20914 0-4 1.79086-4 4s1.79086 4 4 4zm0 2c-3.27164 0-6.17632 1.5711-8.00073 4 1.82441 2.4289 4.72909 4 8.00073 4 3.2716 0 6.1763-1.5711 8.0007-4-1.8244-2.4289-4.7291-4-8.0007-4z"
              fill="#ffffff"
            ></path>
          </svg>
          {lanGs["Вход"][lang]}
        </a>
      </div>
      <svg
        width="183"
        height="21"
        viewBox="0 0 183 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={"HeaderBlock_btn"}
        onClick={() => setCollapseHeader(!collapseHeader)}
      >
        <g filter="url(#filter0_d_519:298)">
          <path d="M21.5559 13L2 0H177L157.946 13H21.5559Z" fill="#024280" />
        </g>
        <defs>
          <filter
            id="filter0_d_519:298"
            x="0"
            y="0"
            width="183"
            height="21"
            filterUnits="userSpaceOnUse"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="2" dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_519:298"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_519:298"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </div>
  );
};

export default HeaderBlock;

import React, {useEffect} from 'react';
import './FooterBlock.css'

import {findDefM, LinksType, titleHeader} from "../../utils/datas";
const FooterBlock = ({moduleList, clickMenu,  collapseFooter, setCollapseFooter, setClickMenu, dataNav, setLinkType, setSubItem}) => {
    let indexNav = (item, its) => {

        let arr = []
        item.map((i, index) =>
            arr.push({name: i[0], id: index})

        )

        return arr.filter(i => i.name.includes(its[0]))[0].id


    }
    let menuItem = moduleList


    useEffect(() => {
        dataNav && clickMenu && setLinkType(LinksType(dataNav.data,  clickMenu[0]).linkType)
        dataNav && clickMenu && setSubItem(titleHeader(dataNav.data,  clickMenu[0]).length > 1? titleHeader(dataNav.data,  clickMenu[0])[1]: titleHeader(dataNav.data,  clickMenu[0])[0] )
    }, [clickMenu, dataNav, setLinkType, setSubItem]);

    let moduleLeft = () => {

        if ((indexNav(moduleList, clickMenu) - 1) >= 0) {

            return setClickMenu(menuItem[(indexNav(moduleList, clickMenu) - 1)])
        } else {

            return setClickMenu(menuItem[menuItem.length - 1])
        }

    }
    let moduleRight = () => {
        if ((indexNav(moduleList, clickMenu) + 1) >= menuItem.length) {
            return setClickMenu(menuItem[0])
        } else {
            return setClickMenu(menuItem[(indexNav(moduleList, clickMenu) + 1)])
        }
    }
    let moduleHome = () => {
        setClickMenu([findDefM(dataNav.data).title, findDefM(dataNav.data).module])
    }


    return (


        <div className={`FooterBlock_wrapper ${collapseFooter ? 'active' : ''}`}>
            <svg width="183" height="21" viewBox="0 0 183 21" fill="142b4b" xmlns="http://www.w3.org/2000/svg"
                 className={'FooterBlock_btn'}
                 style={{transform: 'rotate(180deg)'}}
                 onClick={() => setCollapseFooter(!collapseFooter)}>
                <g filter="url(#filter0_d_519:298)">
                    <path d="M21.5559 13L2 0H177L157.946 13H21.5559Z" fill="#024280"/>
                </g>
                <defs>
                    <filter id="filter0_d_519:298" x="0" y="0" width="183" height="21" filterUnits="userSpaceOnUse">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dx="2" dy="4"/>
                        <feGaussianBlur stdDeviation="2"/>
                        <feComposite in2="hardAlpha" operator="out"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_519:298"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_519:298" result="shape"/>
                    </filter>
                </defs>
            </svg>
            <div className={`FooterBlock_wrap`}>

                <div title={'Перейти на модуль выше'}>
                    <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => moduleList && moduleLeft()}>
                        <path
                            d="M0.319702 10.6959L7.5424 18.6484C7.74857 18.8754 8.02337 19 8.31637 19C8.6097 19 8.88433 18.8752 9.09051 18.6484L9.74628 17.9262C9.95229 17.6996 10.0658 17.3968 10.0658 17.074C10.0658 16.7514 9.95229 16.4385 9.74628 16.2119L5.53266 11.5624L18.9195 11.5624C19.5231 11.5624 20 11.0422 20 10.3774V9.35645C20 8.69173 19.5231 8.11903 18.9195 8.11903L5.48486 8.11903L9.74612 3.44361C9.95213 3.2166 10.0656 2.92211 10.0656 2.59933C10.0656 2.2769 9.95213 1.97811 9.74612 1.75128L9.09035 1.03142C8.88417 0.804419 8.60954 0.680714 8.31621 0.680714C8.0232 0.680714 7.74841 0.80603 7.54223 1.03303L0.31954 8.98533C0.112875 9.21305 -0.000782013 9.51704 3.05176e-05 9.84018C-0.000619888 10.1644 0.112875 10.4686 0.319702 10.6959Z"
                            fill="white"/>
                    </svg>
                </div>
                <div title={'Стартовый модуль'}>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => moduleHome()}>
                        <g clipPath="url(#clip0)">
                            <path
                                d="M19.4603 8.69904C19.4598 8.69858 19.4594 8.69812 19.4589 8.69766L11.3005 0.539551C10.9527 0.19165 10.4904 0 9.99862 0C9.50682 0 9.04448 0.191498 8.69658 0.539398L0.54244 8.69339C0.539693 8.69614 0.536947 8.69904 0.5342 8.70178C-0.179911 9.42001 -0.17869 10.5853 0.53771 11.3017C0.865011 11.6292 1.29729 11.8188 1.75948 11.8387C1.77825 11.8405 1.79717 11.8414 1.81624 11.8414H2.14141V17.8453C2.14141 19.0334 3.10805 20 4.29641 20H7.48824C7.81173 20 8.07418 19.7377 8.07418 19.4141V14.707C8.07418 14.1649 8.51516 13.7239 9.0573 13.7239H10.9399C11.4821 13.7239 11.9231 14.1649 11.9231 14.707V19.4141C11.9231 19.7377 12.1854 20 12.509 20H15.7008C16.8892 20 17.8558 19.0334 17.8558 17.8453V11.8414H18.1573C18.649 11.8414 19.1113 11.6499 19.4594 11.302C20.1765 10.5844 20.1768 9.41711 19.4603 8.69904Z"
                                fill="white"/>
                        </g>
                        <defs>
                            <clipPath id="clip0">
                                <rect width="20" height="20" fill="white"/>
                            </clipPath>
                        </defs>
                    </svg>
                </div>
                <div title={'Перейти на модуль ниже'}>
                    <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => moduleList && moduleRight()}>
                        <path
                            d="M19.6803 8.30408L12.4576 0.351605C12.2514 0.124601 11.9766 0 11.6836 0C11.3903 0 11.1157 0.124781 10.9095 0.351605L10.2537 1.07379C10.0477 1.30044 9.93421 1.60317 9.93421 1.92595C9.93421 2.24856 10.0477 2.56149 10.2537 2.78814L14.4673 7.4376H1.08048C0.476906 7.4376 0 7.95785 0 8.62257V9.64355C0 10.3083 0.476906 10.881 1.08048 10.881H14.5151L10.2539 15.5564C10.0479 15.7834 9.93438 16.0779 9.93438 16.4007C9.93438 16.7231 10.0479 17.0219 10.2539 17.2487L10.9097 17.9686C11.1158 18.1956 11.3905 18.3193 11.6838 18.3193C11.9768 18.3193 12.2516 18.194 12.4578 17.967L19.6805 10.0147C19.8871 9.78695 20.0008 9.48296 20 9.15982C20.0006 8.83561 19.8871 8.53144 19.6803 8.30408Z"
                            fill="white"/>
                    </svg>
                </div>

            </div>


        </div>

    );
};

export default FooterBlock;
